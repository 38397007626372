@use '@angular/material' as mat;
/* You can add global styles to this file, and also import other style files */
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$adkom-primary: mat.define-palette(mat.$teal-palette);
$adkom-accent:  mat.define-palette(mat.$green-palette);

// The warn palette is optional (defaults to red).
$adkom-warn:    mat.define-palette(mat.$deep-orange-palette);

// Create the theme object (a Sass map containing all of the palettes).
$adkom-theme: mat.define-light-theme($adkom-primary, $adkom-accent, $adkom-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($adkom-theme);


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.spacer {
  flex: 1 1 auto;
}

.strings {
  display: none;
}

.empty {
  font-style: italic;
}

.fill-space {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.warn-color {
  color: mat.get-color-from-palette($adkom-warn);
}
